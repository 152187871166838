.container {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
}

.marquee {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee-horizontal 40s linear infinite;
  display: flex;
}

@keyframes marquee-horizontal {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-33%);
  }
}

.track {
  display: flex;
}

.content {
  display: flex;
  margin-right: 2rem;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: auto;
}

.content img {
  width: 7rem;
  max-width: none;
}

.gradientLeft {
  position: absolute;
  height: 60px;
  width: 120px;
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(246, 244, 238, 1) 0%,
    rgba(246, 244, 238, 0) 100%
  );
}

.gradientRight {
  position: absolute;
  height: 60px;
  width: 120px;
  right: 0;
  background: linear-gradient(
    -90deg,
    rgba(246, 244, 238, 1) 0%,
    rgba(246, 244, 238, 0) 100%
  );
}

@media (max-width: 640px) {
  .content {
    grid-column-gap: 1rem;
  }
}
